/** @jsx jsx */
import { graphql } from "gatsby";
import React, { memo } from "react";
import ParsedHTML from "shared/components/ParsedHTML";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetFreeText" }>;
}

const FreeTextWidget: React.FC<Props> = ({
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <ParsedHTML>{widget.freeTextShape?.html || ""}</ParsedHTML>
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetFreeText on WidgetFreeText {
    id
    __typename
    freeTextShape {
      html
    }
  }
`;

export default memo(FreeTextWidget);
